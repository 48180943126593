import React from "react";
import { includes } from "lodash";

import styles from "./text-styling-toolbar.styles.css";
import { click } from "../common/common.utils.js";

const FONT_SIZES = [8, 10, 12, 14, 18, 24, 32];

export default function FontSizeSelectDropdown({ disabled, actions, editorState, fontMenuOpen, editorInstance }) {
  const currentTextSize = getCurrentFontSize(editorState.getCurrentInlineStyle());

  const fontSizeItems = FONT_SIZES.map((fontSize) => {
    if (fontSize === currentTextSize) {
      return (
        <li key={fontSize}>
          <a
            style={{ padding: "0", paddingLeft: "11px", paddingBottom: "4px" }}
            className={`cps-color-primary`}
            onMouseDown={click.bind(
              null,
              actions.toggleInlineFontSize.bind(null, editorState, `${fontSize}pt`, editorInstance),
            )}
            data-testid="test-fontSize"
          >
            {fontSize}
            <i className="cps-icon cps-color-primary cps-icon-sm-check"></i>
          </a>
        </li>
      );
    } else {
      return (
        <li key={fontSize}>
          <a
            className={`${styles.fontSizeMenuItem}`}
            onMouseDown={click.bind(
              null,
              actions.toggleInlineFontSize.bind(null, editorState, `${fontSize}pt`, editorInstance),
            )}
          >
            {fontSize}
          </a>
        </li>
      );
    }
  });

  return (
    <span className={`cps-margin-right-8 cps-dropdown ${fontMenuOpen ? "cps-open" : ""}`}>
      <a
        id="fontSizeMenu"
        data-testid={`${disabled ? "test-fontSizeMenuDisabled" : "test-fontSizeMenu"}`}
        disabled={disabled}
        style={{ paddingTop: "0 !important", paddingBottom: "2px !important", margin: "2px 0px", borderRadius: "5px" }}
        className={`cps-link ${disabled ? styles.gray : ""}`}
        onMouseDown={click.bind(null, actions.toggleFontSizeMenuVisibility.bind(null))}
      >
        {currentTextSize}
        <span className="cps-caret" style={{ marginLeft: "2px" }}></span>
      </a>
      <ul className="cps-dropdown-menu" style={{ minWidth: "38px" }} role="menu">
        {fontSizeItems}
      </ul>
    </span>
  );
}

function getCurrentFontSize(currentInlineStyles) {
  try {
    //currentInlineStyles is an ordered map, the beginning styles have lowest precedence, hence the array is reversed so that find returns the highest precedence text size
    //match the style to be of the form 10pt and check if it is included in FONT_SIZES array, otherwise return 10 (parseInt returns the false value NaN for strings without numeric characters, the second arg specifies a base 10 number)
    return (
      parseInt(
        currentInlineStyles
          .toArray()
          .reverse()
          .find((style) => {
            return style.match(/\d+pt/) && includes(FONT_SIZES, parseInt(style, 10));
          }),
        10,
      ) || 10
    );
  } catch {
    //return default font size
    return 10;
  }
}
