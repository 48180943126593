import { RichUtils } from "draft-js";
import * as types from "./draft.types.js";
import { FONT_MENU_CLOSED, COLOR_MENU_CLOSED, UPDATED_FLASH_ICON_PLACEMENT } from "./editor.types.js";
import { isEqual } from "lodash";

export function updateState(state, flashIconPlacementHelperData) {
  return (dispatch) => {
    updateFlashIconPlacementData(flashIconPlacementHelperData)(dispatch);

    dispatch({
      type: types.UPDATED_EDITOR_STATE,
      editorState: state,
    });
  };
}

export function updateFlashIconPlacementData(flashIconPlacementHelperData) {
  return (dispatch) =>
    dispatch({
      type: UPDATED_FLASH_ICON_PLACEMENT,
      flashIconPlacementHelperData,
    });
}

export function toggleInlineStyle(state, style) {
  const newState = RichUtils.toggleInlineStyle(state, style);

  return {
    type: types.UPDATED_EDITOR_STATE,
    editorState: newState ? newState : state,
  };
}

export function toggleInlineFontSize(state, size) {
  return (dispatch) => {
    let newState = state;

    //Since font sizes are css classes, multiple can be applied to given text. This finds those classes and removes them.
    const currentInlineStyles = state.getCurrentInlineStyle().toArray() || [];
    const currentFontSizes = currentInlineStyles.filter((style) => style.match(/\d+pt/));

    //remove current font sizes
    currentFontSizes.forEach((size) => {
      newState = RichUtils.toggleInlineStyle(newState, size);
    });

    //apply new font size
    newState = RichUtils.toggleInlineStyle(newState, size);

    dispatch({
      type: types.UPDATED_EDITOR_STATE,
      editorState: newState ? newState : state,
    });

    setTimeout(() =>
      dispatch({
        type: FONT_MENU_CLOSED,
      }),
    );
  };
}

export function toggleInlineFontColor(state, color) {
  return (dispatch) => {
    let newState = state;

    //Since font sizes are css classes, multiple can be applied to given text. This finds those classes and removes them.
    const currentInlineStyles = state.getCurrentInlineStyle().toArray() || [];
    const currentFontColors = currentInlineStyles.filter((style) => style.match(/clr/));

    //remove current font colors
    currentFontColors.forEach((aColor) => {
      newState = RichUtils.toggleInlineStyle(newState, aColor);
    });

    //apply new font color, if not black
    if (color !== "BLACKclr") {
      newState = RichUtils.toggleInlineStyle(newState, color);
    }

    dispatch({
      type: types.UPDATED_EDITOR_STATE,
      editorState: newState ? newState : state,
    });

    setTimeout(() =>
      dispatch({
        type: COLOR_MENU_CLOSED,
      }),
    );
  };
}

export function toggleBlockType(state, blocktype) {
  const newState = RichUtils.toggleBlockType(state, blocktype);

  return {
    type: types.UPDATED_EDITOR_STATE,
    editorState: newState ? newState : state,
  };
}

export function handleKeyCommand(state, command) {
  const newState = RichUtils.handleKeyCommand(state, command);

  return {
    type: types.UPDATED_EDITOR_STATE,
    editorState: newState ? newState : state,
  };
}

export function handleTab(state, editorInstance, event) {
  event.preventDefault();

  if (RichUtils.getCurrentBlockType(state) === "atomic") {
    return {
      type: types.UPDATED_EDITOR_STATE,
      editorState: state,
    };
  }

  const newState = RichUtils.onTab(event, state, 4);

  if (isEqual(state, newState)) {
    //Note: _.isEqual causes iterable.length deprecation warnings
    return {
      type: types.BOILERPLATE_TEXT_INSERTED,
      textToInsert: `            `, //12 spaces
      editorInstance: editorInstance,
    };
  } else {
    return {
      type: types.UPDATED_EDITOR_STATE,
      editorState: newState ? newState : state,
    };
  }
}

export function insertMergeField(mergeField, editorInstance, allMergeFields) {
  return {
    type: types.MERGE_FIELD_INSERTED,
    mergeField,
    allMergeFields,
  };
}

export function insertPastedContent(content) {
  return {
    type: types.CONTENT_PASTED,
    content,
  };
}

export function removeBlock(block) {
  return {
    type: types.BLOCK_REMOVED,
    block,
  };
}

export function insertBoilerplateText(textToInsert, editorInstance) {
  return {
    type: types.BOILERPLATE_TEXT_INSERTED,
    textToInsert,
    editorInstance,
  };
}

export function insertImage(encodedImage, editorInstance) {
  return {
    type: types.IMAGE_INSERTED,
    encodedImage,
    editorInstance,
  };
}
