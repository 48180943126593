import React from "react";
import splitTextIntoTextBlocks from "draft-js/lib/splitTextIntoTextBlocks.js";
import getRangesForDraftEntity from "draft-js/lib/getRangesForDraftEntity";
import { getDefaultKeyBinding, SelectionState, DefaultDraftBlockRenderMap, RichUtils } from "draft-js";
import { UserTenantProps, hasAccess } from "cp-client-auth!sofe";
import { primaryNavHeightObs } from "primary-navbar!sofe";

import styles from "./editor.styles.css";

import SecondaryNav from "../secondary-nav/secondary-nav.component.js";
import FlashIcon from "./flash-icon.component.js";
import LeftToolMenu from "../left-pane-tools/tool-menu-left.component.js";
import ClientRequest from "../client-request/client-request.component.js";
import Preview from "../preview/preview.component.js";
import Publish from "./canopy-template/publish.component";
import { forEach, intersection, pull, debounce } from "lodash";
import { getClientUsers } from "../secondary-nav/client-users.resource.js";

const canopyBlockRenderMap = DefaultDraftBlockRenderMap.set("left", { element: "left" }).set("right", {
  element: "right",
});

import Editor, { composeDecorators } from "draft-js-plugins-editor";

import createImagePlugin from "draft-js-image-plugin";
import createAlignmentPlugin from "draft-js-alignment-plugin";
import createFocusPlugin from "draft-js-focus-plugin";
import createResizeablePlugin from "draft-js-resizeable-plugin";
import createDndPlugin from "draft-js-drag-n-drop-plugin";

import createMergeFieldPlugin from "./merge-field.plugin.js";

import { CpLoader } from "canopy-styleguide!sofe";
const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin({
  vertical: "relative",
  horizontal: "relative",
});
const dndPlugin = createDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const mergeFieldPlugin = createMergeFieldPlugin();

const { AlignmentTool } = alignmentPlugin;

const decorator = composeDecorators(
  resizeablePlugin.decorator,
  alignmentPlugin.decorator,
  focusPlugin.decorator,
  dndPlugin.decorator,
);

const imagePlugin = createImagePlugin({ decorator });
const plugins = [dndPlugin, focusPlugin, alignmentPlugin, resizeablePlugin, imagePlugin, mergeFieldPlugin];

@UserTenantProps()
export default class EditorComponent extends React.Component {
  constructor(props) {
    super(props);

    this.dragging = false; // Keep track of dragging so we don't save while dragging

    this.focusEditor = (e) => {
      if (intersection(e.target.classList, ["cps-card", "public-DraftEditorPlaceholder-inner"]).length) {
        this.editorInstance.focus();
      }
    };

    this.debouncedSave = debounce(this.saveDocument.bind(this, this.props.actions.saveDocument), 3000, {
      trailing: true,
      leading: false,
    });

    // Used to set the gutterSize on window resize
    this.debouncedResize = debounce(this.resizeGutter.bind(this), 200);

    this.state = {
      // The gutter size is used to properly calculate the position of the inline image controls
      gutterSize: 0,
      navHeight: 72,
    };
  }

  lettersPermissions = hasAccess(this.props.loggedInUser)("sme_letters");
  lettersPublishPermissions = hasAccess(this.props.loggedInUser)("sme_letters_publish");

  render() {
    const props = this.props;
    const clientRequestDisplayed = props.letterEditorState.clientRequestDisplayed;
    const doc = props.letterEditorState.document;

    setTimeout(() => {
      if (this.wrapper) {
        [8, 10, 12, 14, 18, 24, 32].forEach((size) => {
          forEach(this.wrapper.querySelectorAll(`[style="font-size: ${size}pt;"]`), (el) => {
            if (
              el.parentElement.parentElement.parentElement.tagName === "UL" ||
              el.parentElement.parentElement.parentElement.tagName === "OL"
            ) {
              if (size > 12) {
                el.parentElement.parentElement.parentElement.style.margin = `0 0 0 ${size}pt`;
                el.parentElement.parentElement.style["padding-left"] = `10px`;
              } else {
                el.parentElement.parentElement.parentElement.style.margin = `0 0 0 0`;
                el.parentElement.parentElement.style["padding-left"] = `0px`;
              }

              el.parentElement.parentElement.parentElement.style["font-size"] = `${size}pt`;
            }
          });
        });
      }
    });

    const hasLettersAccess = this.lettersPermissions || this.lettersPublishPermissions;

    return props.letterEditorState.documentLoaded ? (
      <div>
        <div>
          <LeftToolMenu
            actions={props.actions}
            leftMenuToDisplay={props.letterEditorState.leftMenuToDisplay}
            boilerplateText={props.letterEditorState.boilerplateText}
            mergeFields={props.letterEditorState.mergeFields}
            mergeFieldValues={props.letterEditorState.mergeFieldValues}
            documentType={props.letterEditorState.type}
            editorInstance={this.editorInstance}
          />
        </div>

        <div
          className={`${styles.editorContainer} ${adjustPaddingForMenus(props.letterEditorState)} ${
            hasLettersAccess ? styles.editorContainerWide : ""
          }`}
        >
          <div>{props.preview.previewDisplayed ? this.renderPreview() : this.renderEditor()}</div>
          <div className={styles.editorContainerRightSidebar}>
            <div className={`${styles.previewToggle}`} onClick={this.togglePreview.bind(this)}>
              <div className={`cps-card ${styles.toggleImage}`}>
                <img
                  src={`${
                    props.preview.previewDisplayed
                      ? "https://cdn.canopytax.com/images/letter-edit-toggle.png"
                      : doc.preview_image_url
                  }`}
                />
              </div>
              <div className={`cps-margin-top-8 cps-text-center cps-caption cps-wt-bold cps-light-gray`}>
                {props.preview.previewDisplayed ? "Edit View" : "Preview"}
              </div>
            </div>
            {hasLettersAccess && (
              <div className="cps-margin-top-32">
                {/* {this.props.letterEditorState.type === 'template' &&
                <button type="button" className="cps-btn +primary" onClick={this.props.actions.convertTemplateToCanopyTemplate} disabled={this.state.convertingToTemplate}>Convert to Canopy Template</button>} */}
                {this.props.letterEditorState.type === "canopy_template" && this.props.letterEditorState.document && (
                  <Publish canPublish={this.lettersPublishPermissions} />
                )}
              </div>
            )}
          </div>
        </div>

        <div>
          {clientRequestDisplayed ? (
            <ClientRequest
              context={props.context}
              actions={props.actions}
              params={props.match.params}
              requestId={props.letterEditorState.clientRequestId}
              clientRequestDisplayed={props.letterEditorState.clientRequestDisplayed}
              letterTitle={props.letterEditorState.document ? props.letterEditorState.document.title : ""}
            />
          ) : null}
        </div>
        <SecondaryNav {...props} editorInstance={this.editorInstance} />
      </div>
    ) : (
      <div className={`${styles.loaderWrapper}`} data-testid="test-loader">
        <div className={`${styles.loader}`}>
          <CpLoader />
        </div>
      </div>
    );
  }

  componentDidMount() {
    window.previewLetter = () => {
      const html = `<body>${this.props.letterEditorState.document.body}</body>`;
      const myWindow = window.open("data:text/html," + encodeURIComponent(html), "_blank");
      myWindow.focus();
    };

    //Check for a clientId (also tells us that this is a letter and not a template)
    this.props.match.params.clientId &&
      getClientUsers(this.props.match.params.clientId).subscribe((response) => {
        this.props.actions.setNotificationIds(
          pull(
            response.users.map((user) => user.id),
            this.props.loggedInUser.id,
          ),
        );
      });

    this.navHeightDisposable = primaryNavHeightObs.subscribe((navHeight) => this.setState({ navHeight }));

    window.addEventListener("resize", this.debouncedResize);
    document.addEventListener("mousedown", this.props.actions.toggleFlashMenuVisibility);
    document.addEventListener("click", this.props.actions.toggleShareMenuVisibility);
    document.addEventListener("click", this.props.actions.toggleFontSizeMenuVisibility);
    document.addEventListener("click", this.props.actions.toggleFontColorMenuVisibility);

    setTimeout(this.debouncedResize);
  }

  togglePreview() {
    if (this.props.preview.previewDisplayed) {
      this.props.actions.closePreview();
    } else {
      this.props.actions.loadPreview(this.props.match.params);
    }
  }

  renderEditor() {
    const props = this.props;

    const getBlockStyle = (block) => {
      switch (block.getType()) {
        case "left":
          return styles.alignLeft;

        case "center":
          return styles.alignCenter;

        case "right":
          return styles.alignRight;

        default:
          return null;
      }
    };

    const STYLE_MAP = {
      GREYclr: { color: "#8B8B8B" },
      REDclr: { color: "#E51515" },
      BLUEclr: { color: "#0D73D9" },
      ORANGEclr: { color: "#FCB104" },
      PURPLEclr: { color: "#A742FF" },
      GREENclr: { color: "#05C127" },
      BROWNclr: { color: "#685F55" },
      "8pt": { fontSize: "8pt" },
      "10pt": { fontSize: "10pt" },
      "12pt": { fontSize: "12pt" },
      "14pt": { fontSize: "14pt" },
      "18pt": { fontSize: "18pt" },
      "24pt": { fontSize: "24pt" },
      "32pt": { fontSize: "32pt" },
    };

    function blockRenderer(contentBlock) {
      contentBlock.getType();
    }

    return (
      <div
        className={`${styles.bgPageBreak}`}
        ref={(el) => {
          this.contentPane = el;
        }}
        onDrop={this.handleDropOutsideEditor}
      >
        <div className={`${styles.bgPageBreakMask}`}></div>
        <div className={`cps-card ${styles.editorPage}`} onClick={this.focusEditor}>
          <div
            onDragStart={() => {
              this.dragging = true;
            }}
            onDragEnd={() => {
              this.dragging = false;
            }}
            onDrop={() => {
              this.dragging = false;
            }}
            ref={(wrapper) => {
              this.wrapper = wrapper;
            }}
            className="cps-card__body"
            style={{ padding: 0 }}
          >
            <FlashIcon
              actions={props.actions}
              flashMenuDisplayed={props.letterEditorState.flashMenuDisplayed}
              flashIconDisplayed={props.letterEditorState.flashIconDisplayed}
              flashIconPlacementHelperData={props.letterEditorState.flashIconPlacementHelperData}
            />
            <div
              style={{
                position: "relative",
                top: -1 * (this.state.navHeight + 210),
                left: -1 * this.state.gutterSize - 118,
              }}
            >
              <AlignmentTool />
            </div>
            <Editor
              blockRenderMap={canopyBlockRenderMap}
              blockRendererFn={blockRenderer}
              blockStyleFn={getBlockStyle}
              customStyleMap={STYLE_MAP}
              editorState={props.letterEditorState.editorState}
              handlePastedText={this.handlePastedText.bind(this)}
              onChange={onChange.bind(null, props)}
              onTab={props.actions.handleTab.bind(null, props.letterEditorState.editorState, this.editorInstance)}
              handleKeyCommand={handleKeyCommand.bind(null, props.actions, props.letterEditorState.editorState)}
              placeholder={`Start drafting your ${props.letterEditorState.type || "document"}`}
              spellCheck={true}
              ref={(editor) => {
                this.editorInstance = editor;
              }}
              plugins={plugins}
              keyBindingFn={handleKeyPress.bind(this, props.letterEditorState.editorState)}
            />
          </div>
        </div>
      </div>
    );
  }

  handlePastedText(text, html = "") {
    const internalClipboard = this.editorInstance.getClipboard();

    if (!text) return true; // empty clipboard

    // If it is an internal paste, then lets let react handle the paste
    // by returning false. If not internal, we will do our special paste handler

    if (internalClipboard) {
      if (
        html.indexOf(this.editorInstance.getEditorKey()) !== -1 ||
        (splitTextIntoTextBlocks(text).length === 1 &&
          internalClipboard.size === 1 &&
          internalClipboard.first().getText() === text)
      ) {
        return false;
      }
    }

    return this.props.actions.insertPastedContent({ html, text }), true; // returning true prevents default paste
  }

  renderPreview() {
    return <Preview actions={this.props.actions} preview={this.props.preview} params={this.props.match.params} />;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.letterEditorState.documentLoaded === false && this.props.letterEditorState.documentLoaded === true) {
      this.timeoutId = setTimeout(() => (this.editorInstance ? this.editorInstance.focus() : null));
    }

    if (!prevProps.letterEditorState.isSaved && this.props.letterEditorState.isSaved) {
      this.debouncedSave();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.props.actions.toggleFlashMenuVisibility);
    document.removeEventListener("click", this.props.actions.toggleShareMenuVisibility);
    document.removeEventListener("click", this.props.actions.toggleFontSizeMenuVisibility);
    document.removeEventListener("click", this.props.actions.toggleFontColorMenuVisibility);

    window.removeEventListener("resize", this.debouncedResize);

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    if (this.savingTimeoutId) {
      clearTimeout(this.savingTimeoutId);
    }

    if (this.navHeightDisposable) {
      this.navHeightDisposable.unsubscribe();
    }

    this.props.actions.unloadApp();
  }

  saveDocument(saveFn) {
    if (this.props.letterEditorState.isSaved || this.dragging) return;

    saveFn();

    let theSavingMessage = document.querySelector("#savingMessage");
    if (theSavingMessage) {
      theSavingMessage.style.opacity = "1";

      if (this.savingTimeoutId) {
        clearTimeout(this.savingTimeoutId);
      }

      this.savingTimeoutId = setTimeout(() => {
        theSavingMessage.style.opacity = "0";
      }, 3000);
    }
  }

  resizeGutter() {
    if (this.contentPane) {
      this.setState({
        gutterSize: this.contentPane.getBoundingClientRect().left,
      });
    } else if (!this.props.preview.previewDisplayed) {
      // If the contentPane hasn't loaded yet, try running it again
      setTimeout(this.debouncedResize);
    }
  }

  handleDropOutsideEditor = (e) => {
    // determine if the drop occurred within or outside of the editor box
    if (hasParentWithClass(e.target, styles.editorContainer) && !hasParentWithClass(e.target, "DraftEditor-root")) {
      e.preventDefault();
    }
  };
}

function onChange(props, newState) {
  let caretRect,
    existingPositionHelper = props.letterEditorState.flashIconPlacementHelperData;

  caretRect = getCaretRect();

  let foundElement = caretRect || getCurrentBlockRect(newState) || existingPositionHelper;

  let positionHelper = { top: foundElement.top, height: foundElement.height, left: caretRect ? caretRect.left : null };

  props.actions.updateState(newState, positionHelper);
}

function getCaretRect() {
  const s = window.getSelection();
  let caretRect;

  if (s.anchorNode) {
    const oRange = s.getRangeAt(0);

    // The rectangle that bounds the cursor
    caretRect = oRange.getBoundingClientRect();
  }

  return caretRect && caretRect.top ? caretRect : null;
}

function getCurrentBlockRect(newState) {
  let blockRect;
  const currentAnchorKey = newState.getSelection().getAnchorKey(),
    blockEl = document.querySelector(`div[data-offset-key='${currentAnchorKey}-0-0']`);

  if (blockEl) {
    blockRect = blockEl.getBoundingClientRect();
  }

  return blockRect && blockRect.top ? blockRect : null;
}

function handleKeyCommand(actions, existingState, command) {
  const contentState = existingState.getCurrentContent(),
    selection = existingState.getSelection(),
    blockType = RichUtils.getCurrentBlockType(existingState),
    blockKey = selection.getAnchorKey(),
    currentBlock = contentState.getBlockForKey(blockKey);

  if (command === "noop" || (command === "split-block" && blockType === "atomic")) {
    return "handled";
  }

  if ((command === "backspace" || command === "delete") && blockType === "atomic") {
    // This should only be executed for deleting images - we assume
    // only images are "atomic" block types
    let element = currentBlock.toJS().characterList[0];

    if (element) {
      getEntitySelectionState(contentState, selection, element.entity);
      actions.removeBlock(currentBlock);
      return "handled";
    }
  }

  if (
    command === "split-block" &&
    selection.isCollapsed() &&
    ["ordered-list-item", "unordered-list-item"].includes(blockType) &&
    currentBlock.getText() === ""
  ) {
    actions.toggleBlockType(existingState, blockType);
    return "handled";
  }

  actions.handleKeyCommand(existingState, command);
}

function handleKeyPress(editorState, e) {
  let blockType = RichUtils.getCurrentBlockType(editorState);

  if (e.keyCode === 32 && blockType === "atomic") {
    return "noop";
  }

  return getDefaultKeyBinding(e);
}

function hasParentWithClass(el, theClass) {
  if (el.className && el.className.split(" ").indexOf(theClass) >= 0) return true;
  return el.parentNode && hasParentWithClass(el.parentNode, theClass);
}

function getEntitySelectionState(contentState, selectionState, entityKey) {
  const selectionKey = selectionState.getAnchorKey();
  const selectionOffset = selectionState.getAnchorOffset();
  const block = contentState.getBlockForKey(selectionKey);
  const blockKey = block.getKey();

  let entitySelection;
  getRangesForDraftEntity(block, entityKey).forEach((range) => {
    if (range.start <= selectionOffset && selectionOffset <= range.end) {
      entitySelection = new SelectionState({
        anchorOffset: range.start,
        anchorKey: blockKey,
        focusOffset: range.end,
        focusKey: blockKey,
        isBackward: false,
        hasFocus: selectionState.getHasFocus(),
      });
    }
  });
  return entitySelection;
}

function adjustPaddingForMenus(editorState) {
  if (editorState.leftMenuToDisplay) {
    return `${styles.leftToolMenuPresent} ${styles.clientRequestMenuNotPresent}`;
  } else if (editorState.clientRequestDisplayed) {
    return `${styles.leftToolMenuNotPresent} ${styles.clientRequestMenuPresent}`;
  } else {
    return `${styles.leftToolMenuNotPresent} ${styles.clientRequestMenuNotPresent}`;
  }
}
