import * as types from "./client-request.types.js";
import { convertToHTML } from "draft-convert";
import { forkJoin, from, of } from "rxjs";
import { flatMap, pluck } from "rxjs/operators";

import * as clientRequestResource from "./client-request.resource.js";
import toasts from "toast-service!sofe";

/***
 * Saves the client request data and creates a new client request if needed
 ***/
export function saveClientRequest(stateParams, state) {
  let observable;
  if (!state.letterEditor.clientRequestId) {
    //Create Client Request if needed
    observable = clientRequestResource.createClientRequest(stateParams.clientId, stateParams.resolutionCaseId).pipe(
      flatMap((response) => saveClientRequestHelper(response, state, stateParams, response.client_requests.id)),
      flatMap((response) => {
        return of({
          type: types.CLIENT_REQUEST_CREATED,
          clientRequestBody: response,
          requestId: response.client_requests.id,
          requestSent: !!response.client_requests.first_sent_at,
        });
      }),
    );
  } else {
    //Use the existing client request body to build a new object and save the new data
    observable = saveClientRequestHelper(
      state.clientRequest.clientRequestBody,
      state,
      stateParams,
      state.letterEditor.clientRequestId,
    ).pipe(
      flatMap(() => {
        return of({
          type: types.CLIENT_REQUEST_SAVED,
          requestId: state.letterEditor.clientRequestId,
        });
      }),
    );
  }
  return observable;
}

/***
 * Does the actual saving of the client request
 ***/
function saveClientRequestHelper(clientRequestBody, state, stateParams, requestId) {
  const body = { ...clientRequestBody };
  const cr = body.client_requests;
  const letterName = state.letterEditor.document.title;
  const clientId = stateParams.clientId;

  cr.title = state.clientRequest.requestTitle || letterName;
  cr.description = convertToHTML(state.clientRequest.requestEditorState.getCurrentContent());
  cr.due_at = state.clientRequest.dueDate;
  cr.reminders.reminder_interval = state.clientRequest.savedReminders.days || null;
  cr.reminders.expiration_interval_days = `${state.clientRequest.savedReminders.period || ""}` || null;

  // return replaceClientRequestAttachment(clientId, requestId, letterName, stateParams.letterId)
  return forkJoin(
    replaceClientRequestAttachment(clientId, requestId, letterName, stateParams.letterId),
    clientRequestResource.updateClientRequest(clientId, stateParams.resolutionCaseId, requestId, body),
  ).pipe(flatMap(() => of(clientRequestBody)));
}

/***
 * Removes existing attachments and attaches the letter
 ***/
function replaceClientRequestAttachment(clientId, requestId, letterName, letterId) {
  return clientRequestResource.getAttachments(clientId, requestId).pipe(
    pluck("attachments"),
    flatMap((attachments) => {
      let deleteAttachments;
      if (attachments.length > 0) {
        deleteAttachments = from(attachments).pipe(
          flatMap((attachment) => clientRequestResource.deleteAttachment(attachment.client_id, attachment.id)),
        );
      } else {
        deleteAttachments = of(null);
      }

      return forkJoin(
        clientRequestResource.attachLetterToClientRequest(clientId, requestId, letterName, letterId),
        deleteAttachments,
      );
    }),
  );
}

export function sendClientRequest(stateParams, clientRequestId, users) {
  return new Promise((resolve) => {
    const body = {
      client_requests: [
        {
          id: clientRequestId,
          sent: true,
          client_url: getUrlFromState("client.general.requests.instance", {
            clientRequestId: clientRequestId,
            clientId: stateParams.clientId,
          }),
        },
      ],
      notifications: { users: users.map((user) => user.id) },
      client_url_to_requests: getUrlFromState("client.general.requests", { clientId: stateParams.clientId }),
    };

    clientRequestResource.sendClientRequest(stateParams.clientId, JSON.stringify(body)).subscribe(() => {
      resolve({ type: types.CLIENT_REQUEST_DIALOG_CLOSED });
      toasts.successToast("Letter sent to client as Client Request!");
    });
  });
}

function getUrlFromState(state, params) {
  return `${window.location.origin}/#/redirect?state=${encodeURI(state)}&params=${encodeURI(
    JSON.stringify(params || {}),
  )}`;
}

export function formatBytes(bytes, precision) {
  if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
    return "-";
  }

  if (typeof precision === "undefined") {
    precision = 1;
  }

  let units = ["B", "KB", "MB", "GB", "TB"];
  let number = Math.floor(Math.log(bytes) / Math.log(1024));
  let val = (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision);

  if (number === 0) return "Less than 1KB";

  return (val.match(/\.0*$/) ? val.substr(0, val.indexOf(".")) : val) + units[number];
}
