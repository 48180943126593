import React from "react";
import PropTypes from "prop-types";
import MultiDownshift from "./multi-downshift.component";
import styles from "./service-multiselect.styles.css";
import FakeCheckbox from "./fake-checkbox.component";
import { find } from "lodash";

const serviceSlugs = [
  {
    service: "General service",
    subservices: ["General letter"],
  },
  {
    service: "Penalty abatement",
    subservices: ["First-time abatement", "Reasonable cause"],
  },
  {
    service: "Innocent Spouse",
    subservices: ["Innocent Spouse", "Separation of liability", "Equitable relief"],
  },
  {
    service: "Levies",
    subservices: ["Levy appeals", "Preventing levy", "Releasing levy", "Returning property"],
  },
  {
    service: "Trust fund recovery",
    subservices: ["Appeals", "Business-level resolution", "Individual-level resolution"],
  },
];

export default class ServiceMultiSelect extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  };
  render() {
    return (
      <MultiDownshift {...this.props} itemToString={(i) => (i ? `${i.service_slug}.${i.subservice_slug}` : "Service")}>
        {({ getToggleButtonProps, getRemoveButtonProps, isOpen, selectedItems, getItemProps }) => (
          <div className={styles["svc-multi-selector"]}>
            <div className={`${styles["svc-multi-selector__main-input"]} cps-form-control`} {...getToggleButtonProps()}>
              {selectedItems.length > 0 ? (
                selectedItems.map((item, i) => (
                  <div
                    key={i}
                    className={[styles["svc-multi-selector__pill"], styles["svc-multi-selector__pill--green"]].join(
                      " ",
                    )}
                  >
                    <span className={styles["svc-multi-selector__pill-title"]}>{item.subservice_slug}</span>
                    <span className={styles["svc-multi-selector__pill__close"]} {...getRemoveButtonProps({ item })}>
                      <span className="cps-icon cps-icon-sm-neg" aria-hidden="true"></span>
                    </span>
                  </div>
                ))
              ) : (
                <span className={styles["svc-multi-selector__placeholder"]}>Select a value</span>
              )}
            </div>
            {isOpen && (
              <div className={styles["svc-multi-selector__dialog"]}>
                <div className={`${styles["svc-multi-selector__dialog__items"]} cps-card__height-2`}>
                  {serviceSlugs.map(({ service: service_slug, subservices }) => (
                    <div key={service_slug} className={styles["svc-multi-selctor__service–category"]}>
                      <div className="cps-wt-bold">{service_slug}</div>
                      {subservices.map((subservice_slug) => {
                        const item = { subservice_slug, service_slug };
                        const itemIsSelected = !!find(selectedItems, item);
                        return (
                          <div
                            {...getItemProps({
                              item,
                              key: `${service_slug}.${subservice_slug}`,
                              className: styles["svc-multi-selector__list-item"],
                            })}
                          >
                            <FakeCheckbox checked={itemIsSelected} />
                            {subservice_slug}
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </MultiDownshift>
    );
  }
}
