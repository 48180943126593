import React from "react";

import styles from "./letter-name.styles.css";

export default function LetterName({ letterEditorState, actions }) {
  let documentTitle = letterEditorState.unsavedTitleOfDocument || "";

  return (
    <input
      type="text"
      className={`cps-form-control-resize ${styles.documentNameInput}`}
      placeholder="Letter name"
      maxLength="200"
      value={documentTitle}
      onChange={handleValueChange}
      onBlur={handleBlur}
    />
  );

  function handleValueChange(event) {
    actions.changeUnsavedTitleOfDocument(event.target.value);
  }

  function handleBlur(event) {
    if (documentTitle) {
      actions.changeDocumentTitle(letterEditorState.document, event.target.value);
      actions.saveDocument();
    } else {
      actions.changeUnsavedTitleOfDocument(letterEditorState.document.title);
    }
  }
}
