import React from "react";
import { RichUtils } from "draft-js";
import Dropzone from "react-dropzone";
import FontSizeSelectDropdown from "./font-size-select-dropdown.component.js";
import FontColorSelectDropdown from "./font-color-select-dropdown.component.js";
import TextStyling from "./text-styling.component.js";
import toasts from "toast-service!sofe";

export default class TextStylingToolbar extends React.Component {
  constructor(props) {
    super();
    this.updateToolbarStyles(props);
  }

  componentDidUpdate() {
    this.updateToolbarStyles();
  }

  render() {
    const {
      actions,
      documentType,
      editorInstance,
      editorState,
      fontMenuOpen,
      colorMenuOpen,
      params,
      previewDisplayed,
    } = this.props;

    return (
      <div
        id="editingTools"
        style={{ position: "relative" }}
        className="cps-btn-icon dropzone"
        action="#"
        data-testid="test-editingTools"
      >
        <TextStyling
          action={actions.toggleInlineStyle.bind(null, editorState, "BOLD", editorInstance)}
          className="cps-icon-bold"
          active={this.currentInlineStyles.BOLD}
          disabled={previewDisplayed}
        />
        <TextStyling
          action={actions.toggleInlineStyle.bind(null, editorState, "UNDERLINE", editorInstance)}
          className="cps-icon-underline"
          active={this.currentInlineStyles.UNDERLINE}
          disabled={previewDisplayed}
        />
        <TextStyling
          action={actions.toggleInlineStyle.bind(null, editorState, "ITALIC", editorInstance)}
          className="cps-icon-italics"
          active={this.currentInlineStyles.ITALIC}
          disabled={previewDisplayed}
        />
        <TextStyling
          action={actions.toggleBlockType.bind(null, editorState, "left", editorInstance)}
          className="cps-icon-left-aligned"
          active={this.currentBlockType === "unstyled" || this.currentBlockType === "left"}
          disabled={previewDisplayed}
        />
        <TextStyling
          action={actions.toggleBlockType.bind(null, editorState, "center", editorInstance)}
          className="cps-icon-center-aligned"
          active={this.currentBlockType === "center"}
          disabled={previewDisplayed}
        />
        <TextStyling
          action={actions.toggleBlockType.bind(null, editorState, "right", editorInstance)}
          className="cps-icon-right-aligned"
          active={this.currentBlockType === "right"}
          disabled={previewDisplayed}
        />
        <TextStyling
          action={actions.toggleBlockType.bind(null, editorState, "unordered-list-item", editorInstance)}
          className="cps-icon-list"
          active={this.currentBlockType === "unordered-list-item"}
          disabled={previewDisplayed}
        />
        <TextStyling
          action={actions.toggleBlockType.bind(null, editorState, "ordered-list-item", editorInstance)}
          className="cps-icon-number"
          active={this.currentBlockType === "ordered-list-item"}
          disabled={previewDisplayed}
        />
        <FontSizeSelectDropdown
          disabled={previewDisplayed}
          actions={actions}
          editorState={editorState}
          fontMenuOpen={fontMenuOpen}
          editorInstance={editorInstance}
        />
        <FontColorSelectDropdown
          disabled={previewDisplayed}
          actions={actions}
          editorState={editorState}
          colorMenuOpen={colorMenuOpen}
          editorInstance={editorInstance}
        />

        {this.dropzone && (
          <a
            disabled={previewDisplayed}
            className="cps-link cps-margin-right-8"
            onClick={this.openFileSelectionDialog.bind(this)}
          >
            <span className="cps-icon cps-icon-image"></span>
          </a>
        )}

        {documentType === "letter" ? (
          <TextStyling
            action={actions.print.bind(null, params.clientId, params.resolutionCaseId, params.letterId)}
            className="cps-icon-print"
            active={false}
            disabled={false}
          />
        ) : null}
        {documentType === "letter" ? (
          <TextStyling
            action={actions.displaySigningModal.bind(null, {
              ...params,
              ...{ disabled: this.props.isLetterEditor },
            })}
            isEsign={this.props.isLetterEditor}
            className={`cps-icon-sign ${
              window.sessionStorage.getItem(`partialSigningData_${params.letterId}`) ? "cps-color-primary" : null
            }`}
            active={false}
            disabled={false}
          />
        ) : null}

        <div style={{ visibility: "hidden", display: "none" }}>
          <Dropzone
            ref={(el) => {
              this.dropzone = el;
            }}
            accept="image/*"
            disableClick={true}
            disablePreview={true}
            maxSize={2000000}
            multiple={false}
            onDrop={this.handleUploadedFile.bind(this)}
          >
            Drop your image here
          </Dropzone>
        </div>
      </div>
    );
  }

  updateToolbarStyles(props) {
    if (!props) props = this.props;
    this.currentInlineStyles = props.editorState.getCurrentInlineStyle().toObject();
    this.currentBlockType = RichUtils.getCurrentBlockType(props.editorState);
  }

  openFileSelectionDialog() {
    this.dropzone.open();
  }

  handleUploadedFile(acceptedFiles) {
    let reader = new FileReader();
    reader.onload = this.imageIsLoaded.bind(this);
    if (!acceptedFiles || acceptedFiles.length < 1) return;

    try {
      reader.readAsDataURL(acceptedFiles[0]);
    } catch {
      toasts.warningToast(
        "There was a problem trying to insert that file. Make sure the file is an image and try again!",
        "Dismiss",
        null,
        6000,
      );
    }
  }

  imageIsLoaded(e) {
    const imageData = e.target.result;

    this.props.actions.insertImage(imageData, this.props.editorInstance);
  }
}
