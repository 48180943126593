import { handleError } from "src/handle-error.helper.js";
import { RichUtils } from "draft-js";
import { of } from "rxjs";
import { first, switchMap } from "rxjs/operators";

import toasts from "toast-service!sofe";
import { showInviteClientModal } from "clients-ui!sofe";

import { saveClientRequest, sendClientRequest, formatBytes } from "./client-request.actions.helper.js";
import * as clientRequestResource from "./client-request.resource.js";
import { getUsersFromClient } from "./client.resource.js";
import * as types from "./client-request.types.js";

export function animateShowClientRequest(visibility) {
  return {
    type: types.ANIMATE_SHOW_TOGGLED,
    visible: visibility === "visible",
  };
}

export function changeRequestTitle(event) {
  return {
    type: types.REQUEST_TITLE_CHANGED,
    title: event.target.value,
  };
}

export function toggleReminderMenu(reminderModalDisplayed) {
  return {
    type: types.REMINDER_MODAL_TOGGLED,
    reminderModalDisplayed: reminderModalDisplayed,
  };
}

export function setReminderDays(days) {
  return {
    type: types.REMINDER_DAYS_SET,
    reminderDays: days.id,
  };
}

export function setReminderPeriod(period) {
  return {
    type: types.REMINDER_PERIOD_SET,
    reminderPeriod: period.id,
  };
}

export function setReminder() {
  return {
    type: types.REMINDER_SAVED,
  };
}

export function removeReminder() {
  return {
    type: types.REMINDER_REMOVED,
  };
}

export function setDueDate(date) {
  return {
    type: types.DUE_DATE_SET,
    dueDate: date,
  };
}

export function removeDueDate() {
  return {
    type: types.DUE_DATE_REMOVED,
  };
}

export function toggleInlineStyle(state, style, editorInstance) {
  editorInstance.focus();

  let newState = RichUtils.toggleInlineStyle(state, style);

  return {
    type: types.UPDATED_REQUEST_EDITOR_STATE,
    editorState: newState ? newState : state,
  };
}

export function toggleBlockType(state, blocktype, editorInstance) {
  editorInstance.focus();

  let newState = RichUtils.toggleBlockType(state, blocktype);

  return {
    type: types.UPDATED_REQUEST_EDITOR_STATE,
    editorState: newState ? newState : state,
  };
}

export function handleKeyCommand(state, command) {
  const newState = RichUtils.handleKeyCommand(state, command);

  return {
    type: types.UPDATED_REQUEST_EDITOR_STATE,
    editorState: newState ? newState : state,
  };
}

export function updateState(state) {
  return {
    type: types.UPDATED_REQUEST_EDITOR_STATE,
    editorState: state,
  };
}

export function loadClientRequest(clientId, resolutionCaseId, requestId) {
  return (dispatch) => {
    clientRequestResource.getClientRequest(clientId, resolutionCaseId, requestId).subscribe((response) => {
      const cr = response.client_requests;
      dispatch({
        type: types.CLIENT_REQUEST_LOADED,
        clientRequestBody: response,
        requestSent: !!cr.first_sent_at,
        title: cr.title,
        description: cr.description,
        dueDate: cr.due_at,
        reminderDays: cr.reminders.reminder_interval,
        period: cr.reminders.expiration_interval_days,
      });
    }, handleError);

    clientRequestResource.getAttachments(clientId, requestId).subscribe((response) => {
      if (response.attachments.length) {
        const filesize = formatBytes(response.attachments[0].filesize);
        dispatch({
          type: types.CLIENT_REQUEST_ATTACHMENT_LOADED,
          filesize: filesize,
        });
      }
    }, handleError);
  };
}

export function loadEmptyClientRequest() {
  return {
    type: types.EMPTY_CLIENT_REQUEST_LOADED,
  };
}

export function clickedUpdateClientRequest(stateParams) {
  return (dispatch, getState) => {
    const state = getState();

    saveClientRequest(stateParams, state).subscribe((resp) => {
      dispatch(resp);
      toasts.successToast("Client Request Updated!");
    }, handleError);

    dispatch({ type: types.CLIENT_REQUEST_DIALOG_CLOSED });
  };
}

export function clickedSendLater(stateParams) {
  return (dispatch, getState) => {
    const state = getState();
    saveClientRequest(stateParams, state).subscribe((resp) => {
      dispatch(resp);
      toasts.successToast("Client request saved as draft. View and send request on client requests dashboard.");
    }, handleError);

    dispatch({ type: types.CLIENT_REQUEST_DIALOG_CLOSED });
  };
}

/***
 * Saves the client request then sends it to the client
 * TODO - check to make sure a client email has been attached
 ***/
export function clickedSendClientRequest(stateParams) {
  return (dispatch, getState) => {
    getUsersFromClient(stateParams.clientId)
      .pipe(
        first(),
        switchMap((users) => of(users.filter((user) => user.role === "Client"))),
      )
      .subscribe((users) => {
        dispatch({ type: types.GOT_CLIENT_USERS, load: users });

        if (!users.length) {
          showInviteClientModal(
            stateParams.clientId,
            () => {
              dispatch(clickedSendClientRequest(stateParams));
            },
            "Your client hasn't joined you on Canopy yet. If you want to send this request, you'll need to invite them to Canopy's client portal.",
          );
        } else {
          saveClientRequest(stateParams, getState()).subscribe((response) => {
            dispatch(response);
            return sendClientRequest(stateParams, response.requestId, users).then(dispatch);
          }, handleError);
        }
      }, handleError);
  };
}

export function getClientCollaborators(clientId) {
  return (dispatch) => {
    getUsersFromClient(clientId)
      .pipe(switchMap((users) => of(users.filter((user) => user.role === "Client"))))
      .subscribe((users) => dispatch({ type: types.GOT_CLIENT_USERS, load: users }), handleError);
  };
}
