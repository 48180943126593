import React from "react";

import styles from "./tool-menu-left.styles.css";
import InsertTextCategory from "./insert-text-category.component.js";
import { click } from "../common/common.utils.js";
import { CprEmptyState } from "canopy-styleguide!sofe";

export default class InsertTextMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = { activeCategory: null };
  }

  render() {
    const insertFunction =
      this.props.documentType === "template" && this.props.selectedTool === "client-attributes"
        ? this.props.actions.insertMergeField
        : this.props.actions.insertBoilerplateText;

    let categoryKeys = Object.keys(this.props.data);

    let categories = categoryKeys.map((categoryKey) => {
      const category = this.props.data[categoryKey];
      if (category[0]) {
        let categoryName = category[0].category;
        let categoryKey = category[0].categoryKey;

        return {
          list: category,
          categoryName,
          categoryKey,
        };
      }
    });

    let sortedCategories = categories.sort((a, b) => a.categoryName.localeCompare(b.categoryName));

    let itemsToDisplay = sortedCategories.map((item) => {
      return (
        <InsertTextCategory
          categoryName={item.categoryName}
          list={item.list}
          key={item.categoryKey}
          categoryKey={item.categoryKey}
          insertFunction={insertFunction}
          editorInstance={this.props.editorInstance}
          isActiveCategory={this.state.activeCategory === item.categoryKey}
          setActiveCategory={this.setActiveCategory.bind(this)}
          mergeFields={this.props.data}
        />
      );
    });

    return (
      <React.Fragment>
        <div className="cps-margin-top-24">
          <div className={`cps-wt-bold ${styles.sidePaneTitle}`}>
            <span className="cps-margin-left-4">{this.props.title}</span>
            <div>
              <a onMouseDown={click.bind(null, this.props.actions.closeLeftToolMenu.bind(null))} className="cps-link">
                <i className="cps-icon cps-icon-close" />
              </a>
            </div>
          </div>
        </div>
        {itemsToDisplay.length ? itemsToDisplay : this.getNoItemsToDisplayContent()}
      </React.Fragment>
    );
  }

  setActiveCategory(categoryKey) {
    this.setState({ activeCategory: categoryKey });
  }

  getNoItemsToDisplayContent() {
    return (
      <div className={`${styles.sidePanelEmptyState}`}>
        <CprEmptyState iconSrc="es_templates" headText="There are no boilerplate items" showCta={false} />
      </div>
    );
  }
}
