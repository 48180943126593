import React from "react";
import ReactDOMClient from "react-dom/client";

import singleSpaReact from "single-spa-react";
import singleSpaCanopy from "single-spa-canopy";
import { ErrorBoundary } from "error-logging!sofe";

import Root, { initialize } from "./root.routes.js";

const letterGeneratorWithErrorBoundary = ErrorBoundary({ featureName: "letters" })(Root);

const reactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: letterGeneratorWithErrorBoundary,
  domElementGetter,
});

const canopyLifecycles = singleSpaCanopy({
  domElementGetter,
  childAppName: "letters-ui",
  hotload: {
    dev: {
      enabled: true,
      waitForUnmount: false,
    },
  },
  overlay: {
    selectors: [".cps-topnav-secondary"],
  },
});

export const bootstrap = [canopyLifecycles.bootstrap, lettersBootstrap, reactLifecycles.bootstrap];

export const mount = [reactLifecycles.mount, canopyLifecycles.mount];

export const unmount = [reactLifecycles.unmount, canopyLifecycles.unmount];

export const unload = [canopyLifecycles.unload];

function lettersBootstrap() {
  return new Promise<void>((resolve) => {
    initialize(window.loggedInUser, window.tenant);
    resolve();
  });
}

function domElementGetter() {
  let el = document.getElementById("letters-ui");
  if (!el) {
    el = document.createElement("div");
    el.id = "letters-ui";
    document.body.appendChild(el);
  }

  return el;
}
