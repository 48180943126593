import React from "react";

import LetterName from "./letter-name.component.js";
import EditorToolbar from "./editor-toolbar.component.js";
import { TopnavSecondary, usePrimaryNavWidth } from "primary-navbar!sofe";
import styles from "./secondary-nav.styles.css";

export default function SecondaryNav(props) {
  return (
    <TopnavSecondary className={`+with-tabs`} style={{ height: "98px", left: usePrimaryNavWidth(), minWidth: "626px" }}>
      <div className={`${styles.toolbarContainer}`}>
        <div className={`cps-col-xs-4 cps-margin-left-24 cps-padding-0 ${styles.toolbarElement}`}>
          <LetterName letterEditorState={props.letterEditorState} actions={props.actions} />
        </div>
        <div className="cps-col-xs-4 cps-wt-light cps-gray-10">
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <span id="savingMessage" style={{ opacity: "0", transition: "opacity 500ms" }}>
              <i>Saving...</i>
            </span>
          </div>
        </div>
        <div
          className={`cps-col-xs-4 cps-margin-right-24 cps-padding-0 ${styles.toolbarElement}`}
          style={{ justifyContent: "flex-end", flexBasis: "fill" }}
        ></div>
      </div>
      <EditorToolbar
        loggedInUser={props.loggedInUser}
        params={props.match.params}
        previewDisplayed={props.preview.previewDisplayed}
        actions={props.actions}
        letterEditorState={props.letterEditorState}
        editorState={props.letterEditorState.editorState}
        editorInstance={props.editorInstance}
        style={{ height: "49px" }}
      />
    </TopnavSecondary>
  );
}
